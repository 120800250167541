
const tokens = {
    '#': { pattern: /\d/ },
    X: { pattern: /[0-9a-zA-Z]/ },
    S: { pattern: /[a-zA-Z]/ },
    A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
    a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
    '!': { escape: true }
}


function masker(value, mask, masked = true, tokens) {
    return Array.isArray(mask)
        ? dynamicMask(maskit, mask, tokens)(value, mask, masked)
        : maskit(value, mask, masked, tokens)
}

function dynamicMask(maskit, masks, tokens) {
    masks = masks.sort((a, b) => a.length - b.length)
    return function (value, mask, masked = true) {
        var i = 0
        while (i < masks.length) {
            var currentMask = masks[i]
            i++
            var nextMask = masks[i]
            if (!(nextMask && maskit(value, nextMask, true, tokens).length > currentMask.length)) {
                return maskit(value, currentMask, masked, tokens)
            }
        }
        return ''
    }
}

function maskit(value, mask, masked = true, tokens) {
    value = value || ''
    mask = mask || ''
    var iMask = 0
    var iValue = 0
    var output = ''
    
    while (iMask < mask.length && iValue < value.length) {
        var cMask = mask[iMask]
        var masker = tokens[cMask]
        var cValue = value[iValue]
        if (masker && !masker.escape) {
            if (masker.pattern.test(cValue)) {
                output += masker.transform ? masker.transform(cValue) : cValue
                iMask++
            }
            iValue++
        } else {
            if (masker && masker.escape) {
                iMask++ 
                cMask = mask[iMask]
            }
            if (masked) output += cMask
            if (cValue === cMask) iValue++
            iMask++
        }
    }

    var restOutput = ''
    while (iMask < mask.length && masked) {
        var cMask = mask[iMask]
        if (tokens[cMask]) {
            restOutput = ''
            break
        }
        restOutput += cMask
        iMask++
    }

    return output + restOutput
}

function event (name) {
  var evt = document.createEvent('Event')
  evt.initEvent(name, true, true)
  return evt
}

export default  {
    mounted: (el, binding) => {
        let config = binding.value
        
        if (Array.isArray(config) || typeof config === 'string') {
            config = {
                mask: config,
                tokens: tokens
            }
        }

        // if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
        //     const els = el.getElementsByTagName('input')
        //     if (els.length !== 1) {
        //         throw new Error("v-mask directive requires 1 input, found " + els.length)
        //     } else {
        //         el = els[0]
        //     }
        // }

        el.oninput = function (evt) {
            if (!evt.isTrusted) return
            let position = el.selectionEnd
            let digit = el.value[position-1]
            el.value = masker(el.value, config.mask, true, config.tokens)
            
            while (position < el.value.length && el.value.charAt(position-1) !== digit) {
                position++
            }
            
            if (el === document.activeElement) {
                el.setSelectionRange(position, position)
                setTimeout(function () {
                    el.setSelectionRange(position, position)
                }, 0)
            }
            el.dispatchEvent(event('input'))
        }

        var newDisplay = masker(el.value, config.mask, true, config.tokens)
        if (newDisplay !== el.value) {
            el.value = newDisplay
            el.dispatchEvent(event('input'))
        }
    }
}