<template>
    <input 
        class="vz-mask"
        type="text" 
        v-model="display"
        v-mask="config"
        ref="input"
        @input="onInput" 
    />
</template>
    
<script setup lang="ts">
import vMask from "@/directives/mask"

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: ''
    },
    mask: {
        type: [String, Array],
        required: true
    },
    masked: {
        type: Boolean,
        default: false
    },
    tokens: {
        type: Object,
        default: () => ({
            '#': { pattern: /\d/ },
            X: { pattern: /[0-9a-zA-Z]/ },
            S: { pattern: /[a-zA-Z]/ },
            A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
            a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
            '!': { escape: true }
        })
    }
})

const lastValue = ref(null)
const display = ref(props.modelValue)
const input = ref<HTMLInputElement|null>(null)

watch(() => props.modelValue, (newValue) => {
    if (newValue !== lastValue.value) {
        display.value = newValue
        refresh(display.value)
    }
})

watch(() => props.masked, () => {
    refresh(display.value)
})

watch(() => props.mask, () => {
    refresh(display.value)
})

const config = computed(() => {
    return props
})

function masker(value, mask, masked = true, tokens) {
    return Array.isArray(mask)
        ? dynamicMask(maskit, mask, tokens)(value, mask, masked)
        : maskit(value, mask, masked, tokens)
}

function dynamicMask(maskit, masks, tokens) {
    masks = masks.sort((a, b) => a.length - b.length)
    return function (value, mask, masked = true) {
        var i = 0
        while (i < masks.length) {
            var currentMask = masks[i]
            i++
            var nextMask = masks[i]
            if (!(nextMask && maskit(value, nextMask, true, tokens).length > currentMask.length)) {
                return maskit(value, currentMask, masked, tokens)
            }
        }
        return ''
    }
}

const inputControl = computed( () => {
    return input.value
})

function maskit(value, mask, masked = true, tokens) {
    value = value || ''
    mask = mask || ''
    var iMask = 0
    var iValue = 0
    var output = ''
    while (iMask < mask.length && iValue < value.length) {
        var cMask = mask[iMask]
        var masker = tokens[cMask]
        var cValue = value[iValue]
        if (masker && !masker.escape) {
            if (masker.pattern.test(cValue)) {
                output += masker.transform ? masker.transform(cValue) : cValue
                iMask++
            }
            iValue++
        } else {
            if (masker && masker.escape) {
                iMask++
                cMask = mask[iMask]
            }
            if (masked) output += cMask
            if (cValue === cMask) iValue++ 
            iMask++
        }
    }

    var restOutput = ''
    while (iMask < mask.length && masked) {
        var cMask = mask[iMask]
        if (tokens[cMask]) {
            restOutput = ''
            break
        }
        restOutput += cMask
        iMask++
    }

    return output + restOutput
}


function onInput(e: any) {
  refresh(e.target.value, e.isTrusted ? e.target.selectionStart : undefined)
}

function refresh(val: string|number, position = -1) {
  let value = masker(val, props.mask, props.masked, props.tokens)
  if (value !== lastValue.value) {
      lastValue.value = value
      emit('update:modelValue', value)
  }
  // display.value = masker(val, props.mask, true, props.tokens)
  if (position !== -1) {
    inputControl.value?.setSelectionRange(position, position)
  } else {
    display.value = masker(val, props.mask, true, props.tokens)
  }
}

defineExpose({
    inputControl
})


</script>
    